import IconTooltip from './IconTooltip';
import RadioButton from './RadioButton';

export default function IndicatorOutcomeAreaButton({ label, value, state, tipContent, tipPosition, onChange }) {
    let outerClass = "flex items-center justify-between w-full gap-4 p-3 rounded-lg text-left";

    switch (state) {
        case "default":
            outerClass = `${outerClass} bg-white border border-grey03`;
            break;
        case "selected":
            outerClass = `${outerClass} bg-blue03 border border-blue`;
            break;
        case "unselected":
            outerClass = `${outerClass} bg-white border border-grey03 opacity-30`;
            break;
        default:
            break;
    }

    return (
        <button 
            className={outerClass}
            onClick={(_) => {
                if (onChange) {
                    onChange(value, state);
                }
            }}
        >
            <p className="font-vg-regular text-base leading-130 text-black grow">{label}</p>
            <div className="flex items-center gap-1 grow-0">
                {tipContent && tipContent !== "" && (
                    <IconTooltip content={tipContent} position={tipPosition ? tipPosition : "top"}/>
                )}
                <RadioButton 
                    checked={state === "selected"} 
                    onChange={(_) => {
                        if (onChange) {
                            onChange(value, state);
                        }
                    }}
                />
            </div>
        </button>
    );
}