import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useParams } from 'react-router';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import ResourceService from '../../services/ResourceService';
import UiUtils from '../../utils/UiUtils';
import * as ResourceConstants from '../../constants/ResourceConstants';
import * as MatomoConstants from '../../constants/MatomoConstants';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function ContentPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const params = useParams();
    const key = params.key ? params.key : '';

    const [state, setState] = useState({
        content: {},
        loaded: false,
        message: "Loading..."
    }); 

    const formatV1 = useCallback((html) => {
        /* For resources created prior to Admin site / Lexical */
        let fmtHTML;
        if (isDesktop) {
            fmtHTML = html
            .replace(/<p>/g, '<p class="mb-10">')
            .replace(/<p before-list>/g, '<p class="mb-3">')
            .replace(/<p no-b-margin>/g, '<p>')
            .replace(/<h1>/g, '<h1 class="font-vg-medium text-[64px] leading-110 mb-3">')
            .replace(/<h2>/g, '<h3 class="font-vg-medium text-5xl leading-110 mb-3">')
            .replace(/<h4>/g, '<h4 class="font-vg-medium text-[38px] leading-120 mb-3">')
            .replace(/<h5>/g, '<h5 class="font-vg-medium text-2xl leading-120 mb-3">')
            .replace(/<h6>/g, '<h6 class="font-vg-medium text-xl leading-120 mb-3">')
            .replace(/<ul>/g, '<ul class="list-disc font-vg-book text-black text-base ps-8 mb-10">')
            .replace(/<ol>/g, '<ol class="list-decimal font-vg-book text-black text-base ps-8 mb-10">')
            .replace(/<p highlight>/g, '<p class="w-full font-vg-medium text-black text-xl leading-130 bg-grey03 bg-content-highlight bg-contain bg-right-bottom bg-no-repeat px-4 py-6 rounded-lg mb-10">')
            .replace(/<p credit>/g, '<p class="font-vg-book text-grey text-xs2 leading-120 mb-5">')
            .replace(/<img src/g, '<img class="w-full mb-10" src')
            .replace(/<img credit src/g, '<img class="w-full mb-3" src');
        } else {
            fmtHTML = html
            .replace(/<p>/g, '<p class="mb-8">')
            .replace(/<p before-list>/g, '<p class="mb-3">')
            .replace(/<p no-b-margin>/g, '<p>')
            .replace(/<h1>/g, '<h1 class="font-vg-medium text-[64px] leading-110 mb-3">')
            .replace(/<h2>/g, '<h3 class="font-vg-medium text-5xl leading-110 mb-3">')
            .replace(/<h4>/g, '<h4 class="font-vg-medium text-[38px] leading-120 mb-3">')
            .replace(/<h5>/g, '<h5 class="font-vg-medium text-2xl leading-120 mb-3">')
            .replace(/<h6>/g, '<h6 class="font-vg-medium text-xl leading-120 mb-3">')
            .replace(/<ul>/g, '<ul class="list-disc font-vg-book text-black text-base ps-8 mb-8">')
            .replace(/<ol>/g, '<ol class="list-decimal font-vg-book text-black text-base ps-8 mb-8">')
            .replace(/<p highlight>/g, '<p class="w-full font-vg-medium text-black text-xl leading-130 bg-grey03 bg-content-highlight bg-contain bg-right-bottom bg-no-repeat px-4 py-6 rounded-lg mb-8">')
            .replace(/<p credit>/g, '<p class="font-vg-book text-grey text-xs2 leading-120 mb-5">')
            .replace(/<img src/g, '<img class="w-full mb-10" src')
            .replace(/<img credit src/g, '<img class="w-full mb-3" src');
        }
        return fmtHTML;
    }, [isDesktop]);

    const formatV2 = useCallback((html) => {
        return html;
    }, []);

    const format = useCallback((editorVersion, html) => {
        return editorVersion === 1 ? formatV1(html) : formatV2(html);
    }, []);
    
    /* Matomo Tracking Code */

    const { trackPageView } = useMatomo();

    const sendTrackingCode = useCallback((category, title) => {
        trackPageView({
            documentTitle: MatomoConstants.PageTitle.RESOURCE + " / " + category + " / " + title
        })
    }, []);
    
    /* Content Loading Functions */
    
    const loadContent = useCallback(() => {
        const hrefParts = window.location.href.split("/");
        const preKey = hrefParts[hrefParts.length - 2];
        const serviceType = preKey === "preview" ? ResourceConstants.ServiceType.DRAFT_PREVIEW : ResourceConstants.ServiceType.AUTH_CHECK;
        ResourceService.getResourceByServiceType(serviceType, key)
        .then(response => {
            const data = response.data;
            if (data.access) {
                const resource = data.resource;
                const editorVersion = resource.editorVersion ? resource.editorVersion : 1;
                setState({
                    content: {
                        title: resource.title,
                        lead: resource.lead ? resource.lead : "",
                        body: format(editorVersion, resource.body ? resource.body : ""),
                        footnotes: resource.footnotes.length > 0 ? format(editorVersion, resource.footnotes.map((f, index) => {
                            const marginBottom = index < resource.footnotes - 1 ? " mb-4" : "";
                            return `<p class="font-vg-book text-black text-xs2 leading-120${marginBottom}">${f}</p>`
                        }).join('')) : "",
                        embedCode: resource.embedCode ? resource.embedCode : ""
                    },
                    loaded: true,
                    message: ""
                });
                sendTrackingCode(resource.category, resource.title);
            } else {
                setState({
                    content: {},
                    loaded: false,
                    message: response.message
                });
                sendTrackingCode("Error", "Resource Not Found");
            }
        })
        .catch(err => {
            console.log(err);
            let message = "Unable to load resource. Please try again later.";
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message;
            }
            setState({
                content: {},
                loaded: false,
                message: message
            });
        });
    }, [key, setState, format, sendTrackingCode]);

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    let containerClass = "box-border w-full flex flex-col items-stretch gap-10";
    containerClass = isDesktop ? `${containerClass} py-6 px-16` : `${containerClass} py-6 px-5`;

    return(
        <div className={containerClass}>
            <Header 
                title={state.content.title ? state.content.title : ""} 
                pageTitle="Learning Resources" 
                pageDescription={state.content.title ? state.content.title : ""}
            />
            {state.loaded === false && (
            <div className="flex justify-center items-center max-w-content-narrow mx-auto min-h-[450px]">
                <h4 className="font-vg-medium text-3xl text-black">{state.message}</h4>
            </div>
            )}
            {isDesktop && state.loaded === true && (
            <div className="grid grid-cols-12">
                <div className="col-start-3 col-span-8">
                    {state.content.embedCode ? (
                        <div className="w-full mb-10 text-center" dangerouslySetInnerHTML={{__html: state.content.embedCode}}></div>
                    ) : (
                        <>
                        {state.content.lead && (
                            <p className="font-vg-regular text-black text-xl mb-10" dangerouslySetInnerHTML={{__html: state.content.lead}}></p>
                        )}
                        </>
                    )}
                    {state.content.body && (
                        <div className="font-vg-book text-black text-base" dangerouslySetInnerHTML={{__html: state.content.body}}></div>
                    )}
                    {state.content.footnotes && (
                        <>
                        <div className="h-0 border border-grey03 mb-4"></div>
                        <div dangerouslySetInnerHTML={{__html: state.content.footnotes}}></div>
                        </>
                    )}
                </div>
            </div>
            )}
            {isMobile && state.loaded === true && (
            <div class="flex flex-col items-stretch">
                {state.content.embedCode ? (
                    <div className="w-full mb-8 text-center" dangerouslySetInnerHTML={{__html: state.content.embedCode}}></div>
                ) : (
                    <>
                    {state.content.lead && (
                        <p className="font-vg-regular text-black text-xl mb-8" dangerouslySetInnerHTML={{__html: state.content.lead}}></p>
                    )}
                    </>
                )}
                {state.content.body && (
                    <div className="font-vg-book text-black text-base" dangerouslySetInnerHTML={{__html: state.content.body}}></div>
                )}
                {state.content.footnotes && (
                    <>
                    <div className="h-0 border border-grey03 mb-4"></div>
                    <div dangerouslySetInnerHTML={{__html: state.content.footnotes}}></div>
                    </>
                )}
            </div>
            )}
            <Footer/>
        </div>
    );
}