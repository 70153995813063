import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

import PrivateRoute from './components/PrivateRoute';

import AboutPage from './pages/front/AboutPage';
import HomePage from './pages/front/HomePage';
import ResourcesPage from './pages/front/ResourcesPage';
import HelpPage from './pages/front/HelpPage';
import ContactPage from './pages/front/ContactPage';
import ContentPage from './pages/front/ContentPage';

import TermsPage from './pages/front/TermsPage';
import PrivacyPage from './pages/front/PrivacyPage';

import SignUpPage from './pages/auth/SignUpPage';
import SignUpVerifyPage from './pages/auth/SignUpVerifyPage';

import LoginPage from './pages/auth/LoginPage';
import LoginVerifyPage from './pages/auth/LoginVerifyPage';
import LoginVerifyManualPage from './pages/auth/LoginVerifyManualPage';

import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ForgotSentPage from './pages/auth/ForgotSentPage';
import ForgotVerifyPage from './pages/auth/ForgotVerifyPage';

import EmailChangeVerifyPage from './pages/auth/EmailChangeVerifyPage';

import KitHomePage from './pages/toolkit/KitHomePage';
import OrganisationPage from './pages/toolkit/OrganisationPage';
import EnterprisePage from './pages/toolkit/EnterprisePage';
import KitResourcesPage from './pages/toolkit/KitResourcesPage';
import ProgProjectPage from './pages/toolkit/ProgProjectPage';
import IndicatorsPage from './pages/toolkit/IndicatorsPage';
import DataManagementPage from './pages/toolkit/DataManagementPage';
import DashboardsPage from './pages/toolkit/DashboardsPage';
import UserManagementPage from './pages/toolkit/UserManagementPage';

import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import NewMemberUserPage from './pages/auth/NewMemberUserPage';

import DesktopOnlyPage from './pages/front/DesktopOnlyPage';

import TestPage from './pages/test/TestPage';

import * as GeneralConstants from './constants/GeneralConstants';
import { GlobalContext } from './context/GlobalContext';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-quill/dist/quill.snow.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles/lexical.css';

import './App.css';

export default function App() {
    const storedContext = sessionStorage.getItem(GeneralConstants.CONTEXT_STORAGE_KEY);

    const replacer = function(key, value) {
        if (this[key] instanceof Date) {
            return this[key].toISOString();
        }
        return value;
    }

    const [context, setContext] = useState(storedContext ? JSON.parse(storedContext) : {});
    const setContextValues = (values) => {
        if (values) {
            let cxt = JSON.parse(JSON.stringify(context, replacer));
            values.forEach(v => {
                cxt[v.key] = v.value;
            });
            setContext(cxt);
            sessionStorage.setItem(GeneralConstants.CONTEXT_STORAGE_KEY, JSON.stringify(cxt, replacer));
        }
    };

    const matomoInstance = createInstance({
        urlBase: process.env.REACT_APP_MATOMO_URL_BASE,
        siteId: process.env.REACT_APP_MATOMO_SITE_ID
    });

    return(
        <MatomoProvider value={matomoInstance}>
            <GlobalContext.Provider value={{ context, setContextValues }}>
                <div className="app-container">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<HomePage/>}/>
                            <Route path="/about" element={<AboutPage/>}/>
                            <Route path="/resources" element={<ResourcesPage/>}/>
                            <Route path="/help" element={<HelpPage/>}/>
                            <Route path="/contact" element={<ContactPage/>}/>
                            <Route path="/terms" element={<TermsPage/>}/>
                            <Route path="/privacy" element={<PrivacyPage/>}/>

                            <Route path="/test" element={<TestPage/>}/>

                            <Route path="/resources/:key" element={<ContentPage/>}/>
                            <Route path="/resources/preview/:key" element={<ContentPage/>}/>

                            <Route path="/signup" element={<SignUpPage/>}/>
                            <Route path="/signup/member/verify/:token/:code" element={<NewMemberUserPage/>}/>
                            <Route path="/signup/verify/:token/:code" element={<SignUpVerifyPage/>}/>

                            <Route path="/login" element={<LoginPage/>}/>
                            <Route path="/login/verify" element={<LoginVerifyManualPage/>}/>
                            <Route path="/login/verify/:token/:code" element={<LoginVerifyPage/>}/>

                            <Route path="/password/forgot" element={<ForgotPasswordPage/>}/>
                            <Route path="/password/forgot/sent" element={<ForgotSentPage/>}/>
                            <Route path="/password/forgot/verify/:token/:code" element={<ForgotVerifyPage/>}/>

                            <Route path="/change/verify/:token/:code" element={<EmailChangeVerifyPage/>}/>

                            <Route path="/kit/home" element={
                                <PrivateRoute element={<KitHomePage/>} redirect="/login"/>
                            }/>
                            <Route path="/kit/organisation" element={
                                <PrivateRoute element={<OrganisationPage/>} redirect="/login"/>
                            }/>
                            <Route path="/kit/enterprise" element={
                                <PrivateRoute element={<EnterprisePage/>} redirect="/login"/>
                            }/>
                            <Route path="/kit/progproject" element={
                                <PrivateRoute element={<ProgProjectPage/>} redirect="/login"/>
                            }/>
                            <Route path="/kit/indicators" element={
                                <PrivateRoute element={<IndicatorsPage/>} redirect="/login"/>
                            }/>
                            <Route path="/kit/datamanagement" element={
                                <PrivateRoute element={<DataManagementPage/>} redirect="/login"/>
                            }/>
                            <Route path="/kit/dashboards" element={
                                <PrivateRoute element={<DashboardsPage/>} redirect="/login"/>
                            }/>
                            <Route path="/kit/resources" element={
                                <PrivateRoute element={<KitResourcesPage/>} redirect="/login"/>
                            }/>
                            <Route path="/kit/usermanagement" element={
                                <PrivateRoute element={<UserManagementPage/>} redirect="/login"/>
                            }/>

                            <Route path="/password/change" element={
                                <PrivateRoute element={<ChangePasswordPage/>} redirect="/login"/>
                            }/> 

                            <Route path="/desktoponly" element={<DesktopOnlyPage/>}/>   
                        </Routes>
                    </BrowserRouter>
                </div>
            </GlobalContext.Provider>
        </MatomoProvider>
    );
}
