import { Fragment, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';

import { GlobalContext } from '../context/GlobalContext';
import AuthService from '../services/AuthService';

import Button from './Button';

export default function LoginExpiryModal({ open }) {
    const {setContextValues} = useContext(GlobalContext);

    const navigate = useNavigate();

    const doneClass = 'col-span-2 font-vg-regular text-black text-base text-center';
    const [statusText, setStatusText] = useState('');
    const [hideSubmit, setHideSubmit] = useState(false);

    const clearContext = () => {
        setContextValues([
            { key: "user", value: {} },
            { key: "organisation", value: {} },
            { key: "authToken", value: "" },
            { key: "selections", value: {} }
        ]);
    };

    const onCloseLogout = (e) => {
        e.preventDefault();
        setHideSubmit(true);
        setStatusText("Processing...");
        clearContext();
        AuthService.logout()
        .then((_) => {
            setHideSubmit(false);
            navigate('/login');
        })
        .catch(error => {
            setHideSubmit(false);
            console.log(error);
            navigate('/login');
        });
    }

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onCloseLogout}>
            <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"                
                >
                <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
                </TransitionChild>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">\
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative flex flex-col items-stretch gap-8 transform rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[600px]">
                                <div className="flex flex-col items-stretch gap-8">
                                    <DialogTitle
                                        as="h4"
                                        className="font-vg-medium text-3.5xl text-black leading-110"
                                    >
                                        Login Session Expired
                                    </DialogTitle>
                                    <p className="font-vg-book text-black text-base">
                                        For security reasons, logged in sessions on Seedkit now expire after one hour. Click on the button below to return to the login page where you can log back in.
                                    </p>
                                    <Button variant="outline" size="large" label="Go To Login Page"  className={hideSubmit ? "hidden" : "w-full"} onClick={onCloseLogout}/>
                                    <p className={hideSubmit ? doneClass : "hidden"} aria-live="polite">{statusText}</p>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}