import { addClassNamesToElement } from '@lexical/utils';
import { ElementNode } from 'lexical';
 
function $convertLayoutContainerElement(domNode) {
    /*
    const styleAttributes = window.getComputedStyle(domNode);
    const templateColumns = styleAttributes.getPropertyValue(
        'grid-template-columns',
    );   
    */
    const templateColumns = domNode.style.gridTemplateColumns;
    if (templateColumns) {
        const node = $createLayoutContainerNode(templateColumns);
        return {node};
    }
    return null;
}
 
export class LexicalLayoutContainerNode extends ElementNode {
    constructor(templateColumns, key) {
        super(key);
        this.__templateColumns = templateColumns;
    }
    
    static getType() {
        return 'layout-container';
    }
    
    static clone(node) {
        return new LexicalLayoutContainerNode(node.__templateColumns, node.__key);
    }
    
    createDOM(config) {
        const dom = document.createElement('div');
        dom.style.gridTemplateColumns = this.__templateColumns;
        if (typeof config.theme.layoutContainer === 'string') {
            addClassNamesToElement(dom, config.theme.layoutContainer);
        }
        this.__theme = config.theme;
        return dom;
    }
    
    exportDOM() {
        const element = document.createElement('div');
        element.style.gridTemplateColumns = this.__templateColumns;
        element.setAttribute('data-lexical-layout-container', 'true');

        const classNames = this.__theme && typeof this.__theme.layoutContainer === 'string' ? this.__theme.layoutContainer : 'LexicalTheme__layoutContainer';
        addClassNamesToElement(element, classNames);

        return {element};
    }
    
    updateDOM(prevNode, dom) {
        if (prevNode.__templateColumns !== this.__templateColumns) {
            dom.style.gridTemplateColumns = this.__templateColumns;
        }
        return false;
    }
    
    static importDOM() {
        return {
            div: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-layout-container')) {
                    return null;
                }
                return {
                    conversion: $convertLayoutContainerElement,
                    priority: 2,
                };
            },
        };
    }
    
    static importJSON(json) {
        return $createLayoutContainerNode(json.templateColumns);
    }
    
    isShadowRoot() {
        return true;
    }
    
    canBeEmpty() {
        return false;
    }
    
    exportJSON() {
        return {
            ...super.exportJSON(),
            templateColumns: this.__templateColumns,
            type: 'layout-container',
            version: 1,
        };
    }
    
    getTemplateColumns() {
        return this.getLatest().__templateColumns;
    }
    
    setTemplateColumns(templateColumns) {
        this.getWritable().__templateColumns = templateColumns;
    }
}
 
export function $createLayoutContainerNode(templateColumns) {
   return new LexicalLayoutContainerNode(templateColumns);
}

export function $isLayoutContainerNode(node) {
   return node instanceof LexicalLayoutContainerNode;
}
 