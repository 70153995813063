import axios from 'axios';

import * as ResourceConstants from '../constants/ResourceConstants';

let ResourceService = {};

ResourceService.getResources = (publicOnly, category) => {
    let url = `${process.env.REACT_APP_API_URL}/resource`;
    if (publicOnly) {
        url = `${url}/public`;
    }
    url = `${url}?category=${category}`;
    
    return axios.get(url, 
        { withCredentials: true }
    );
};

const getResourceByKeyAuthCheck = (key) => {
    let url = `${process.env.REACT_APP_API_URL}/resource/checkauth/key/${key}`;    
    return axios.get(url, 
        { withCredentials: true }
    );
};

const getPreviewResource = (key) => {
    let url = `${process.env.REACT_APP_API_URL}/resource/preview/key/${key}`;    
    return axios.get(url, 
        { withCredentials: true }
    );
}

ResourceService.getResourceByKeyAuthCheck = getResourceByKeyAuthCheck;
ResourceService.getPreviewResource = getPreviewResource;

ResourceService.getResourceByServiceType = (type, key) => {
    if (type === ResourceConstants.ServiceType.DRAFT_PREVIEW) {
        return getPreviewResource(key);
    } else {
        return getResourceByKeyAuthCheck(key);
    }
}

export default ResourceService;