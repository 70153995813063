export default {
    code: 'editor-code',
    embedBlock: {
        base: 'LexicalTheme__embedBlock',
        focus: 'LexicalTheme__embedBlockFocus',
    },
    heading: {
        h1: 'editor-heading-h1',
        h2: 'editor-heading-h2',
        h3: 'editor-heading-h3',
        h4: 'editor-heading-h4',
        h5: 'editor-heading-h5',
    },
    image: 'editor-image',
    inlineImage: 'inline-editor-image',
    layoutContainer: 'LexicalTheme__layoutContainer',
    layoutItem: 'LexicalTheme__layoutItem',
    link: 'editor-link',
    list: {
        checklist: '',
        listitem: 'editor-listitem',
        listitemChecked: 'LexicalTheme__listItemChecked',
        listitemUnchecked: 'LexicalTheme__listItemUnchecked',
        nested: {
            listitem: 'editor-nested-listitem',
        },
        ol: 'editor-list-ol',
        ul: 'editor-list-ul',
    },
    ltr: 'ltr',
    paragraph: 'editor-paragraph',
    placeholder: 'editor-placeholder',
    quote: 'editor-quote',
    rtl: 'rtl',
    text: {
        bold: 'editor-text-bold',
        code: 'editor-text-code',
        hashtag: 'editor-text-hashtag',
        italic: 'editor-text-italic',
        overflowed: 'editor-text-overflowed',
        strikethrough: 'editor-text-strikethrough',
        underline: 'editor-text-underline',
        underlineStrikethrough: 'editor-text-underlineStrikethrough',
    },
    table: 'LexicalTheme__table',
    tableAddColumns: 'LexicalTheme__tableAddColumns',
    tableAddRows: 'LexicalTheme__tableAddRows',
    tableCell: 'LexicalTheme__tableCell',
    tableCellActionButton: 'LexicalTheme__tableCellActionButton',
    tableCellActionButtonContainer:
        'LexicalTheme__tableCellActionButtonContainer',
    tableCellEditing: 'LexicalTheme__tableCellEditing',
    tableCellHeader: 'LexicalTheme__tableCellHeader',
    tableCellPrimarySelected: 'LexicalTheme__tableCellPrimarySelected',
    tableCellResizer: 'LexicalTheme__tableCellResizer',
    tableCellSelected: 'LexicalTheme__tableCellSelected',
    tableCellSortedIndicator: 'LexicalTheme__tableCellSortedIndicator',
    tableResizeRuler: 'LexicalTheme__tableCellResizeRuler',
    tableSelected: 'LexicalTheme__tableSelected',
    tableSelection: 'LexicalTheme__tableSelection',
};
